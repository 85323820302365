<template>
  <div v-show="success" class="info-block info-block--success">
    <p>{{ success }}</p>
  </div>
  <div v-show="error" class="info-block info-block--alert">
    <h3>Внимание</h3>
    <p v-for="item in error">{{item}}</p>
  </div>
  <form v-if="user" @submit.prevent="save" method="POST"
        class="registration__form personal-form personal-active--form">
    <label class="personal-label">
      <span class="personal__form--descr">Ваше имя</span>
      <input v-model="fio" type="text" placeholder="Как вас зовут?" class="personal-input personal-active--input">
    </label>
    <label class="personal-label">
      <span class="personal__form--descr">Дата рождения <span>*</span></span>
      <Datepicker v-model="birthday" :clearable="false" language="ru" :locale="ru" inputFormat="dd.MM.yyyy" :enableTimePicker="false" :startingViewDate="startDate" required class="personal-input personal-active--input input-date" placeholder="__.__.____"></Datepicker>
    </label>
    <label class="personal-label">
      <span class="personal__form--descr">Телефон <span>*</span></span>
      <InputMask type="tel" inputmode="tel" v-model="phone" mask="+7 (999) 999-99-99" required class="personal-input input-tel" placeholder="+7 (___) ___-__-__"></InputMask>
    </label>
    <label class="personal-active--label personal-label">
      <span class="personal__form--descr">E-mail <span>*</span></span>
      <input v-model="email" required type="email" placeholder="test@mail.ru" class="personal-input personal-active--input">
    </label>
    <button type="submit" class="btn-reset personal__form--btn">Сохранить изменения</button>
  </form>
</template>

<script>
import axios from "axios";
import InputMask from 'primevue/inputmask';
import Datepicker from 'vue3-datepicker'
import {ru} from 'date-fns/locale'
window['ru'] = ru;

export default {
  data: () => ({
    success: false,
    edit: false,
    email: '',
    phone: '',
    fio: '',
    birthday: '',
    error: false,
    startDate: new Date(1990, 1)
   }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    ru() {
      return window['ru'];
    },
    user() {
      let userInfo = this.$store.getters.user;
    if (userInfo ) {
      if (userInfo.PERSONAL_BIRTHDAY) {
        let date = userInfo.PERSONAL_BIRTHDAY.split('.');
      this.birthday = new Date(date[2], parseInt(date[1]) - 1, date[0]);
    }
      this.email = userInfo.EMAIL;
      this.phone = userInfo.PERSONAL_PHONE;
      this.fio = userInfo.NAME;
    }
      return this.$store.getters.user;
    }
  },
  methods: {
    save() {
      this.error = false;
      this.success = false;

      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('birthday', this.birthday.getFullYear()+"-"+(this.birthday.getMonth()+1)+"-"+this.birthday.getDate());
      let _this = this;
      axios.post( '/local/api/personal/profile/save',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.success = res.data.text;
          _this.$store.dispatch('User', {
            PERSONAL_BIRTHDAY: _this.birthday,
            EMAIL: _this.email,
            PERSONAL_PHONE: _this.phone,
            NAME: _this.fio
          })
          $(window).scrollTop($(".info-block--success").offset().top);
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    }
  },
  created() {

  },
  components: {InputMask, Datepicker }
}
</script>

